<template>
<div style="text-align: left">
  <Card>
    <div class="search-con search-con-top">
      <Button @click="prev()" class="search-btn" type="primary" ghost>返回上一页</Button>&nbsp;
      <template v-if="this.$route.params.action==='real'">
        <Button type="primary" @click="resettask" ghost >全量发布</Button>&nbsp;&nbsp;&nbsp;&nbsp;
        <Button type="primary" @click="taskdetails(url,idinfo)" ghost >刷新</Button>&nbsp;&nbsp;&nbsp;&nbsp;
        <Poptip
            confirm
            title="确定终止任务运行"
            @on-ok="stoptask"
            @on-cancel="cancel"
            ok-text="yes"
            cancel-text="no">
          <Button type="warning" ghost>终止</Button>
        </Poptip>
      </template>
    </div>
      <h2>查看任务进度: {{ taskname }} &nbsp;&nbsp;方法: {{ operationtype }} &nbsp;&nbsp;&nbsp;&nbsp;操作: {{operationargs }}</h2>
      <div :style="{ color:'#fff', background:'#454845', padding: '10px',}" v-html="loggingData"></div>
    </Card>
  </div>
</template>

<script>
import {get} from "@/api/http.js";

export default {
  name: "Index",
  data() {
    return {
      url: '',
      idinfo:{id:""},
      intervalId: null,
      is_refreh: true,
      loggingData: [],
      operationtype: "",
      operationargs: "",
      taskname: "",

    }
  },
  methods: {
    taskdetails(url,idinfo) {
      get(url,idinfo)
          .then((resp) => {
            this.loggingData = resp.data["details"]
            this.taskname = resp.data.taskname
            if (resp.data.playbook_name) {
              this.operationargs = resp.data.playbook_name + '.yaml'
              this.operationtype = "playbook"
            } else {
              this.operationargs = resp.data.args
              this.operationtype = resp.data.modular
            }
            if (resp.data["state"] !== 9) {
              this.is_refreh = false
              clearInterval(this.intervalId);
            }
          }).catch(e => {
        return e
      })
      // this.clearrefreh()
    },
    dataRefreh(id) {
      if (this.isnumber(id)) {
        if (this.is_refreh === true) {
          this.intervalId = setInterval(() => {
            this.taskdetails(id); //加载数据函数
          }, 2000);
        } else {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }
    },
    isnumber(val) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
      if (regPos.test(val) || regNeg.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    cancel () {
      this.$Message.info('You click cancel');
    },

    resettask() {
      const id = this.$route.params.id
      get(`/goansible/api/v1/task/reset?id=`+ id)
          .then((resp)=>{
            this.$Message['info']({background: true,content: resp.msg})
          }).catch(e=>{
        return e
      })
    },

    stoptask() {
      const id = this.$route.params.id
      get(`/goansible/api/v1/task/stop?id=`+ id)
          .then((resp)=>{
            console.log(resp)
            this.gettaskinfo()
          }).catch(e=>{
        return e
      })
    },
    prev() {
      this.$router.go(-1)
    },

    action_url() {
      const action = this.$route.params.action
      const id = this.$route.params.id
      if (action === "real") {
        this.url = "/goansible/api/v1/task/details"
      } else if (action === "history") {
        this.url = "/goansible/api/v1/task/auditdetails"
      } else {
        console.log("不存在操作")
      }
      this.idinfo.id = id
    },

  },

  created() {
    this.action_url()
    this.taskdetails(this.url,this.idinfo)
    this.dataRefreh(this.$route.params.id)
  },
  destroyed() {
    // 在页面销毁后，清除计时器
    clearInterval(this.intervalId)
  }
}
</script>

<style lang="less" scoped>
.content ivu-layout-content {
  text-align: right;
}

.search-con {
  padding: 10px 0;

  .search {
    &-col {
      display: inline-block;
      width: 200px;
    }

    &-input {
      display: inline-block;
      width: 250px;
      margin-left: 2px;
    }

    &-btn {
      margin-left: 2px;
    }
  }
}
</style>
